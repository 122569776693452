<template>
  <div class="flex flex-col gap-4">
    <div>
      <b-button
        variant="primary"
        size="sm"
        @click="addAccount"
        :disabled="loadingCount > 0"
      >
        Add new account
      </b-button>
    </div>

    <table class="table table-google">
      <thead>
        <tr>
          <th>Login</th>
          <th>Name</th>
          <th>Group</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="account in accounts" :key="account.User">
          <td class="row-fit">
            <span class="text-nowrap">{{ account.User }}</span>
          </td>
          <td>
            <span class="text-nowrap">{{ account.Name }}</span>
          </td>
          <td class="row-fit">
            <span class="text-nowrap">{{ account.GroupName }}</span>
          </td>
          <td class="row-fit">
            <div class="flex gap-2">
              <b-button
                variant="primary"
                size="sm"
                :disabled="loadingCount > 0"
                @click="editAccount(account)"
              >
                Edit
              </b-button>

              <b-button
                variant="danger"
                size="sm"
                :disabled="loadingCount > 0"
                @click="deleteAccount(account)"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>

        <tr v-if="accounts.length === 0">
          <td colspan="4">
            <span v-if="loadingCount > 0">Loading..</span>
            <span v-else>No accounts found.</span>
          </td>
        </tr>
      </tbody>
    </table>

    <user-account-modal
      v-if="accountModal.showModal"
      :data="accountModal"
      @saved="onAccountSaved"
    />
  </div>
</template>

<script>
const UserAccountModal = () => import('@/components/admin/UserAccountModal.vue');

export default {
  name: 'UserAccounts',
  components: {
    UserAccountModal,
  },
  data() {
    return {
      loadingCount: 0,
      accounts: [],
      accountModal: {
        showModal: false,
        username: '',
      },
    };
  },
  methods: {
    fetchAccounts() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'user' })
        .send({ method: 'users' })
        .then((res) => {
          this.accounts = Object.keys(res.body).map((user) => {
            const account = {
              ...res.body[user],
              User: user,
            };
            return account;
          });
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    addAccount() {
      this.accountModal.username = null;
      this.accountModal.showModal = true;
    },
    editAccount(account) {
      this.accountModal.username = account.User;
      this.accountModal.showModal = true;
    },
    deleteAccount(account) {
      const confirmed = confirm(`Do you really wish to delete account '${account.User}'?`);
      if (!confirmed) {
        return;
      }
      const params = {
        user: account.User,
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'user' })
        .send({ method: 'userDel' })
        .send({ params })
        .then(() => {
          this.accounts = this.accounts.filter((item) => item.User !== account.User);
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onAccountSaved() {
      this.fetchAccounts();
    },
  },
  created() {
    this.fetchAccounts();
  },
};
</script>

<style lang="scss" scoped>
</style>
